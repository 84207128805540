import React from 'react'
import { Link } from 'react-router-dom'

export const AccountLogin = () => (
  <div className='acontainer'>
    <div>
      <Link
        className='btn-submit mb-5'
        to={{
          pathname: '/connexion'
        }}
      >Se connecter
      </Link>
    </div>
    <div>
      <Link
        className='btn-default'
        to={{
          pathname: '/creer-un-compte'
        }}
      >S'inscrire
      </Link>
    </div>
  </div>
)
