import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import ScrollToTop from './Component/ScrollToTop'
import { Footer } from './Component/Footer'
import { PrivateRoute } from './Services/PrivateRoute'
import { LoginPage } from './Page/LoginPage'
import { MenuMainPage } from './Page/MenuMainPage'
import { CreateAccount } from './Page/CreateAccountPage'
import { Infos } from './Page/InfosPage'
import { InfosDetail } from './Page/InfosDetailPage'
import { ForgottenPassword } from './Page/ForgottenPassword'
import { AccountPage } from './Page/AccountPage'
import { RequestSendPage } from './Page/RequestSendPage'
import { RequestSendValidPage } from './Page/RequestSendValidPage'
import { Error } from './Page/Error'
import { RequestPage } from './Page/RequestPage'
import { RequestListPage } from './Page/RequestListPage'
import { ContactPage } from './Page/ContactPage'
import { UsefullInfosPage } from './Page/UsefullInfosPage'
import { ListUsefullInfosPage } from './Page/ListUsefullInfosPage'
import { UsefullInfosDetailPage } from './Page/UsefullInfosDetailPage'
import { Page } from './Page/Page'
import { AccountParamPage } from './Page/AccountParamPage'
import { PublicRoute } from './Services/PublicRoute'

class App extends Component {
  constructor () {
    super()
    const user = JSON.parse(localStorage.getItem('user'))
    this.state = {
      isAuthenticated: user !== null,
      user: user || null,
      redirect: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.user !== prevState.user) {
      localStorage.setItem('user', JSON.stringify(this.state.user))
    }
  }

  logIn = user => {
    user &&
      this.setState({
        user: JSON.parse(user),
        isAuthenticated: true
      })
  }

  logOut = () => {
    localStorage.removeItem('user')
    this.setState({
      user: null,
      isAuthenticated: false
    })
  }

  render () {
    const { isAuthenticated, user } = this.state

    return (
      <div className='pageWrapper'>
        <Router>
          <ScrollToTop>
            <main className='contentWrapper'>
              <PublicRoute
                exact
                path='/connexion'
                isAllowed={!isAuthenticated}
                component={props => <LoginPage {...props} logIn={this.logIn} />}
              />
              <PublicRoute
                isAllowed={!isAuthenticated}
                exact
                path='/creer-un-compte'
                component={CreateAccount}
              />
              <Route
                exact
                path='/mot-de-passe'
                component={props => (
                  <ForgottenPassword
                    {...props}
                    isAuthenticated={isAuthenticated}
                  />
                )}
              />
              <Route
                exact
                path='/menu'
                component={props => (
                  <MenuMainPage {...props} isAllowed={isAuthenticated} />
                )}
              />
              <Route
                exact
                path='/'
                component={props => <Page {...props} title='Accueil' />}
              />
              <Route exact path='/erreur' component={Error} />
              <Route exact path='/actus' component={Infos} />
              <Route path='/actus/:term' component={InfosDetail} />
              <Route
                exact
                path='/infos-pratiques'
                component={UsefullInfosPage}
              />
              <Route
                exact
                path='/infos-pratiques/:term'
                component={ListUsefullInfosPage}
              />
              <Route
                exact
                path='/infos-pratiques/:term/:name'
                component={UsefullInfosDetailPage}
              />
              <Route
                exact
                path='/aide'
                component={props => <Page {...props} title='Aide' />}
              />
              <Route
                exact
                path='/mot-du-maire'
                component={props => <Page {...props} title='Mot du Maire' />}
              />
              <Route exact path='/contact' component={ContactPage} />
              <Route
                exact
                path='/a-propos'
                component={props => <Page {...props} title='À propos' />}
              />
              <Route
                exact
                path='/requete/envoi'
                isAuthenticated={isAuthenticated}
                user={user}
                component={props => (
                  <RequestSendPage
                    {...props}
                    isAuthenticated={isAuthenticated}
                    user={user}
                  />
                )}
              />
              <Route
                exact
                path='/requete/envoi/valide'
                component={RequestSendValidPage}
              />
              <PrivateRoute
                exact
                path='/mon-compte/mes-requetes'
                isAllowed={isAuthenticated}
                component={props => (
                  <RequestListPage
                    {...props}
                    isAuthenticated={isAuthenticated}
                    user={user}
                  />
                )}
              />
              <PrivateRoute
                path='/mon-compte/requete/:id'
                isAllowed={isAuthenticated}
                component={props => (
                  <RequestPage
                    {...props}
                    isAuthenticated={isAuthenticated}
                    user={user}
                  />
                )}
              />
              <PrivateRoute
                exact
                path='/parametres'
                isAllowed={isAuthenticated}
                component={props => (
                  <AccountParamPage
                    {...props}
                    logOut={this.logOut}
                    isAuthenticated={isAuthenticated}
                    user={user}
                  />
                )}
              />
              <PrivateRoute
                exact
                path='/mon-compte'
                isAllowed={isAuthenticated}
                component={props => (
                  <AccountPage
                    {...props}
                    isAuthenticated={isAuthenticated}
                    user={user}
                  />
                )}
              />
            </main>
          </ScrollToTop>
          <Route
            render={props => {
              return <Footer location={props.location} />
            }}
          />
        </Router>
      </div>
    )
  }
}

export default App
