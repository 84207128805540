import React from 'react'
import { Map, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet-control-geocoder/dist/Control.Geocoder.js'

const height = { height: '420px' }
const center = { lat: 49.2773251, lng: -0.2584647 }
const geocoder = L.Control.Geocoder.nominatim({
  geocodingQueryParams: {
    countrycodes: 'fr',
    city: 'Ouistreham'
  }
})

class Localisation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      localisation: '',
      localisationLat: '',
      localisationLng: '',
      geocoder: {},
      suggestions: [],
      showGeoloc: false,
      dontBlur: false
    }

    L.Map.include({
      clearMarkers: function () {
        this.eachLayer(function (layer) {
          if (layer.hasOwnProperty('_icon')) {
            this.removeLayer(layer)
          }
        }, this)
      }
    })

    this.onLocalisationChange = this.onLocalisationChange.bind(this)
    this.suggestionSelected = this.suggestionSelected.bind(this)
    this.onLocalisationFocus = this.onLocalisationFocus.bind(this)
    this.onLocalisationBlur = this.onLocalisationBlur.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.geolocateDevice = this.geolocateDevice.bind(this)
  }

  componentDidMount () {
    if (this.props.localisation && this.props.localisation.coords && this.props.localisation.localisation) {
      this.setMarker(this.props.localisation.localisation, this.props.localisation.coords)
      this.setState({
        localisation: this.props.localisation.localisation
      })
    }

    const map = this.leafletMap.leafletElement
    map.on('click', e => {
      this.setState({
        localisationLat: e.latlng.lat,
        localisationLng: e.latlng.lng
      })
      this.reverse(e.latlng)
    })
  }

  onLocalisationChange (e) {
    const { name, value } = e.target
    this.setState({ [name]: value })

    this.geocode(value, results => {
      this.setState({
        suggestions: results || []
      })
    })
  }

  onLocalisationFocus (e) {
    const { value } = e.target
    this.geocode(value, results => {
      this.setState({
        suggestions: results || [],
        showGeoloc: true
      })
    })
  }

  onLocalisationBlur (e) {
    if (!this.state.dontBlur) {
      this.setState({
        suggestions: [],
        showGeoloc: false
      })
    }
  }

  geocode (val, func) {
    var inputValue = `${val} Ouistreham`
    geocoder.geocode(inputValue, func)
  }

  reverse (latLng) {
    geocoder.reverse(
      latLng,
      12,
      results => {
        this.setState({
          localisation: results[0].name,
          localisationLat: latLng.lat,
          localisationLng: latLng.lng,
          suggestions: [],
          showGeoloc: false
        })
        this.setMarker(results[0].name, latLng)
      }
    )
  }

  setMarker (name, latLng) {
    const map = this.leafletMap.leafletElement
    var markerGroup = L.layerGroup().addTo(map)
    const icon = L.divIcon({
      className: 'icofont-location-pin',
      iconSize: [28, 40],
      popupAnchor: [5, -12]
    })
    map.clearMarkers()
    L.marker(latLng, { icon: icon })
      .bindPopup(name, { maxWidth: 200 })
      .addTo(markerGroup)
      .openPopup()
    map.flyTo(latLng, 18)
  }

  suggestionSelected (e) {
    this.setState({
      localisation: e.item.name,
      localisationLat: e.item.center.lat,
      localisationLng: e.item.center.lng,
      suggestions: [],
      showGeoloc: false
    }, () => {
      this.props.localisationChange(this.state)
    })

    this.setMarker(e.item.name, e.item.center)
  }

  onMouseEnter (e) {
    this.setState({
      dontBlur: true
    })
  };

  onMouseLeave (e) {
    this.setState({
      dontBlur: false
    })
  };

  geolocateDevice () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.reverse({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude
        })
      })
    }
  }

  render () {
    const { localisation, suggestions, showGeoloc } = this.state
    return (
      <div>
        <input
          type='text'
          name='localisation'
          id='inputLocation'
          value={localisation}
          onChange={this.onLocalisationChange}
          onFocus={this.onLocalisationFocus}
          onBlur={this.onLocalisationBlur}
          placeholder={"Localisation de l'incident"}
        />
        <ul className='suggestLocalisation' onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          {showGeoloc && <li className='flex items-center justify-between' onClick={() => this.geolocateDevice()} id='boutonGeoloc'>Me Geolocaliser<span className='icofont-focus pr-2' /></li>}
          {suggestions && suggestions.map((item, i) => <li key={i} onClick={() => this.suggestionSelected({ item })}>{item.name}</li>)}
        </ul>
        <Map
          animate={false}
          duration={0}
          style={height}
          center={center}
          zoom={14}
          ref={m => {
            this.leafletMap = m
          }}
        >
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </Map>
      </div>
    )
  }
}

export { Localisation }
