import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import slugify from 'slugify'

import { Header } from '../Component/Header'
import { InfosUsefullService } from '../Services/InfosUsefullService'

class ListUsefullInfosPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      infos: []
    }
  }

  componentDidMount () {
    const { location } = this.props
    InfosUsefullService.getInfosByThemeRoot(location.state)
      .then(
        text => {
          const data = JSON.parse(text)
          this.setState({
            infos: data || ''
          })
        },
        error => this.setState({ error, loading: false })
      )
  }

  render () {
    const {
      infos
    } = this.state
    const {
      location, history
    } = this.props
    return (
      <div className='InfosWrapper'>
        <Header title='Infos pratiques' history={history} subpage={location.subpage} />
        <div className='mainContent paddingOnlyLeft'>
          <ul className='InfosListe'>
            {!isEmpty(infos) && infos.map((item, i) =>
              <li className='flex items-center justify-between' key={i}>
                <Link
                  className='w-full'
                  to={{
                    pathname: `${location.pathname}/${slugify(item.titre, {
                      replacement: '-',
                      remove: null,
                      lower: true
                    })}`,
                    state: item.id,
                    subpage: this.props.location.subpage
                  }}
                >
                  {item.titre}
                </Link>
                <i className='icofont-simple-right icofont-md pr-8' />
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  }
}

export { ListUsefullInfosPage }
