import React, { Component } from 'react'

import { Header } from '../Component/Header'
import { Modal } from '../Component/Modal'
import { AccountLogin } from '../Component/AccountLogin'
import { DeletePhoto } from '../Component/DeletePhoto'
import { SelectTheme } from '../Component/SelectTheme'
import { Localisation } from '../Component/Localisation'
import { SimpleContentService } from '../Services/SimpleContentService'
import { RequeteService } from '../Services/RequeteService'
import { CustomCheckbox } from '../Component/CustomCheckbox'
import { traduction } from '../Services/Traduction'

class RequestSendPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      text: '',
      files: [],
      imgs: [],
      themes: [],
      image: {},
      prenom: '',
      nom: '',
      submitted: false,
      localisation: '',
      coords: '',
      notification_resolution: false,
      notification_requete: false,
      showModalImage: false,
      showModalAccount: false,
      imgCounter: 0
    }
  }

  componentDidMount () {
    /*    if (this.props.location.state) {
      this.setState(this.props.location.state)
      this.setState({
        showModal: false,
        showModalAccount: false
      })
    } */

    SimpleContentService.textRequete('requete').then(
      text => {
        const data = JSON.parse(text)
        this.setState({
          text: data.message ? data.message : ''
        })
      },
      error => {
        let msgError = "Une erreur s'est produite."
        if (typeof error === 'string') {
          msgError = traduction.trad(error)
        }
        this.setState({ error: msgError, loading: false })
      }
    )
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleCheckboxChange = name => event => {
    this.setState({ ...this.state, [name]: event.target.checked })
  }

  handleTouchMove = event => event.preventDefault

  handleFileChange = e => {
    const { files, imgs } = this.state

    if (imgs.length === 2 && files.length === 2) {
      e.preventDefault()
      return false
    }

    files.push(e.target.files[0])
    imgs.push({
      url: URL.createObjectURL(e.target.files[0]),
      name: e.target.files[0].name
    })

    this.setState({
      files: files,
      imgs: imgs,
      imgCounter: imgs.length
    })
  }

  handleLocalisationChange = e => {
    const { localisation, localisationLat, localisationLng } = e
    this.setState({
      localisation,
      coords: {
        lat: localisationLat,
        lng: localisationLng
      }
    })
  }

  handleThemeChange = e => {
    const themesId = []
    if (e !== null) {
      e.forEach(function (element) {
        themesId.push(element.value)
      })
    }
    this.setState({
      themes: themesId
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { user } = this.props

    this.setState({ submitted: true })
    const { description, nom, prenom } = this.state

    if (!description) {
      return
    }

    if (!user) {
      if (!prenom && !nom) {
        return
      }
    }

    const token = user ? user.csrf_token : ''
    this.setState({ loading: true })
    RequeteService.sendRequete(token, this.state).then(
      text => {
        this.setState({ loading: false })
        if (text) {
          this.props.history.push('/requete/envoi/valide')
        } else {
          this.props.history.push('/error')
        }
      },
      error => this.setState({ error, loading: false })
    )
  }

  handleModalImage = e => {
    this.setState(
      {
        image: {
          url: e.target.getAttribute('src'),
          name: e.target.getAttribute('title')
        },
        showModalImage: true
      },
      this.setBodyScrollDisabled(true)
    )
  }

  handleModalAccount = () => {
    const { isAuthenticated } = this.props
    if (!isAuthenticated) {
      this.setState(
        {
          showModalAccount: true
        },
        this.setBodyScrollDisabled(true)
      )
    }
  }

  setBodyScrollDisabled = disabled => {
    if (disabled) {
      document.body.classList.add('noScroll')
      document.body.addEventListener('touchmove', this.handleTouchMove, false)
    } else {
      document.body.classList.remove('noScroll')
      document.body.removeEventListener(
        'touchmove',
        this.handleTouchMove,
        false
      )
    }
  }

  deletePhoto = e => {
    const { files, imgs } = this.state
    const url = e.target.getAttribute('data-url')
    const name = e.target.getAttribute('data-name')

    imgs.forEach(function (element, index, object) {
      if (element.url === url) {
        object.splice(index, 1)
      }
    })

    files.forEach(function (element, index, object) {
      if (element.name === name) {
        object.splice(index, 1)
      }
    })

    this.setState({
      files: files,
      imgs: imgs,
      imgCounter: imgs.length
    })

    this.closeModal()
  }

  closeModal = e => {
    this.setState(
      {
        image: {},
        showModalImage: false,
        showModalAccount: false
      },
      this.setBodyScrollDisabled(false)
    )
  }

  render () {
    const {
      text,
      loading,
      description,
      imgs,
      showModalImage,
      showModalAccount,
      image,
      nom,
      prenom,
      submitted,
      imgCounter
    } = this.state
    const { isAuthenticated } = this.props
    return (
      <div className=''>
        <Header title='Requête' />
        <div className='mainContent'>
          <div className='contentText'>
            <p>{text}</p>
          </div>
          <form name='form' onSubmit={this.handleSubmit} autoComplete='off'>
            {!isAuthenticated && (
              <div className='form-group'>
                <input
                  type='text'
                  placeholder='Nom*'
                  className='inputDefaultTheme'
                  id='inputNom'
                  name='nom'
                  onChange={this.handleChange}
                  value={nom}
                />
                {submitted && !nom && (
                  <div className='help-block'>Le nom est requis</div>
                )}

                <input
                  type='text'
                  placeholder='Prénom*'
                  className='inputDefaultTheme'
                  id='inputPrenom'
                  name='prenom'
                  onChange={this.handleChange}
                  value={prenom}
                />
                {submitted && !prenom && (
                  <div className='help-block'>Le prénom est requis</div>
                )}
              </div>
            )}

            <div className='form-group'>
              <h2>
                Photos de l’incident -{' '}
                <span className='img-counter'>{imgCounter}/2</span>
              </h2>
              <div className='flex -mx-2 mb-5'>
                {imgs &&
                  imgs.map((item, i) => (
                    <div className='w-1/2 px-2 incident-photo' key={i}>
                      <img
                        src={item.url}
                        className='rounded'
                        title={item.name}
                        alt=''
                        onClick={this.handleModalImage}
                      />
                    </div>
                  ))}
              </div>
              {imgCounter < 2 && (
                <div className='w-full'>
                  <input
                    type='file'
                    accept='image/*'
                    capture='camera'
                    onChange={this.handleFileChange}
                    id='inputfile'
                    disabled={imgCounter === 2 && true}
                  />
                  <label htmlFor='inputfile'>Ajouter des photos</label>
                </div>
              )}
            </div>

            <div className='form-group'>
              <h2>Description de l'incident*</h2>
              <textarea
                className='form-control'
                id='inputDescription'
                name='description'
                onChange={this.handleChange}
                value={description}
                placeholder={"Description de l'incident"}
                cols='30'
                rows='10'
              />
              {submitted && !description && (
                <div className='help-block'>Une description est requise</div>
              )}
            </div>

            <div className='form-group'>
              <h2>A quelles catégories associer l’incident ?</h2>
              <div className='selectTheme'>
                <SelectTheme
                  themeChange={this.handleThemeChange}
                  defaultThemeState={this.props.location.state}
                />
              </div>
            </div>

            <div className='form-group map'>
              <h2>Vous vous trouvez sur le lieu de l’incident ?</h2>
              <Localisation
                localisationChange={this.handleLocalisationChange}
                localisation={this.props.location.state}
              />
            </div>

            <div className='form-group' onClick={this.handleModalAccount}>
              <CustomCheckbox
                onChange={this.handleCheckboxChange('notification_resolution')}
                disabled={!isAuthenticated}
                name='notification_resolution'
              >
                <label className='text-primary font-medium'>
                  Je souhaite être notifié de l’avancée de la résolution du
                  problème
                </label>
              </CustomCheckbox>

              <CustomCheckbox
                onChange={this.handleCheckboxChange('notification_requete')}
                disabled={!isAuthenticated}
                name='notification_requete'
              >
                <label className='text-primary'>
                  Je m’abonne aux notifications liées à ma requête
                </label>
              </CustomCheckbox>
            </div>

            <div className='form-group'>
              <div className='mb-2'>
                <p className='text-primary text-xs'>
                  Conformément à la législation en vigueur en termes de
                  Protection des données personnelles, remplir ce formulaire
                  entraîne le traitement de vos données personnelles par la
                  Ville de Ouistreham Riva-Bella, fondé sur votre consentement,
                  et uniquement dans le but de traiter votre requête. Pour
                  l’exercice de vos droits, retrouvez toutes les informations
                  <a
                    className='text-primary text-xs'
                    rel='noopener noreferrer'
                    href='http://ouistreham-rivabella.fr/mentions-legales/protection-de-la-vie-privee-rgpd/'
                    target='_blank'
                  >
                    &nbsp; ICI
                  </a>
                </p>
                <p className='text-primary mb-2'>
                  <span className='text-3xl'>*</span>Champs Obligatoires
                </p>
              </div>
              <button
                className='btn-submit'
                disabled={loading}
                name='save'
                id='submitButton'
              >
                Envoyer ma requête
              </button>
              {loading && (
                <img
                  alt=''
                  className='mx-auto'
                  src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                />
              )}
            </div>
          </form>
        </div>

        {showModalImage && (
          <Modal customClass='image' closeModal={this.closeModal}>
            <DeletePhoto image={image} deletePhoto={this.deletePhoto} />
          </Modal>
        )}
        {showModalAccount && (
          <Modal customClass='account' closeModal={this.closeModal}>
            <AccountLogin location={this.props.location} state={this.state} />
          </Modal>
        )}
      </div>
    )
  }
}

export { RequestSendPage }
