import React, { Component } from 'react'

import { BasicPage } from '../Page/BasicPage'
import { InfosUsefullService } from '../Services/InfosUsefullService'

class UsefullInfosDetailPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      info: {}
    }
  }

  componentDidMount () {
    const { location } = this.props
    InfosUsefullService.getSingleInfoById(location.state)
      .then(
        text => {
          const data = JSON.parse(text)
          this.setState({
            info: data || ''
          })
        },
        error => this.setState({ error, loading: false })
      )
  }

  render () {
    const { history, subpage } = this.props
    return (
      <BasicPage
        history={history}
        subpage={subpage}
        content={this.state.info}
      />
    )
  }
}

export { UsefullInfosDetailPage }
