import Conf from '../Configuration/Configuration'

export const InfosUsefullService = {
  getThemesRoot,
  getInfosByThemeRoot,
  getSingleInfoById
}

function getThemesRoot () {
  return fetch(`${Conf.BaseURL}api/infos-utiles?_format=json`)
    .then(handleResponse)
    .then(text => {
      return text
    })
}

function getInfosByThemeRoot (term) {
  return fetch(`${Conf.BaseURL}api/infos-utiles?_format=json&term=${term}`)
    .then(handleResponse)
    .then(text => {
      return text
    })
}

function getSingleInfoById (nid) {
  return fetch(`${Conf.BaseURL}api/infos-utiles/${nid}?_format=json`)
    .then(handleResponse)
    .then(text => {
      return text
    })
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text
    if (!response.ok) {
      if (response.status === 401) {
        document.location.reload(true)
      }
      const dataObj = JSON.parse(data)
      const error = (data && dataObj.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
