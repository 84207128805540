import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { userService } from '../Services/UserService'
import { traduction } from '../Services/Traduction'

import { SubmitButton } from '../Component/SubmitButton'

import logo from '../assets/images/logo.jpg'

class CreateAccount extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      mail: '',
      firstName: '',
      submitted: false,
      loading: false,
      error: '',
      redirect: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit (e) {
    e.preventDefault()

    this.setState({ submitted: true })
    const { username, firstName, mail, password } = this.state

    if (!(username && firstName && mail && password)) {
      return
    }

    this.setState({ loading: true })
    userService.createAccount(username, firstName, mail, password).then(
      user => {
        this.setState({ redirect: true, loading: false })
      },
      error => {
        let msgError = "Une erreur s'est produite."
        if (typeof error === 'string') {
          msgError = traduction.trad(error)
        }
        this.setState({ error: msgError, loading: false })
      }
    )
  }

  render () {
    const {
      username,
      password,
      mail,
      firstName,
      submitted,
      loading,
      error,
      redirect
    } = this.state
    if (redirect)
      return (
        <Redirect
          to={{
            pathname: '/connexion',
            state: { message_success: "L'Utilisateur a été créé avec succés" }
          }}
        />
      )
    return (
      <div className='px-8 py-4'>
        <div className='mb-6'>
          <img className='mx-auto mb-6' src={logo} alt='logo Ouistreham' />
          <h1 className='no-style font-bold text-center text-primary text-base'>
            Se créer un compte
          </h1>
        </div>
        <form name='form w-100 mb-6' onSubmit={this.handleSubmit}>
          <div
            className={
              'form-group' + (submitted && !username ? ' has-error' : '')
            }
          >
            <input
              type='text'
              className='form-control inputDefaultTheme'
              name='username'
              value={username}
              onChange={this.handleChange}
              placeholder='Nom'
            />
            {submitted && !username && (
              <div className='help-block'>Le nom est requis</div>
            )}
          </div>
          <div
            className={
              'form-group' + (submitted && !firstName ? ' has-error' : '')
            }
          >
            <input
              type='text'
              className='form-control inputDefaultTheme'
              name='firstName'
              value={firstName}
              onChange={this.handleChange}
              placeholder='Prenom'
            />
            {submitted && !firstName && (
              <div className='help-block'>Le prénom est requis</div>
            )}
          </div>
          <div
            className={'form-group' + (submitted && !mail ? ' has-error' : '')}
          >
            <input
              type='email'
              className='form-control inputDefaultTheme'
              name='mail'
              value={mail}
              onChange={this.handleChange}
              placeholder='Adresse mail'
            />
            {submitted && !mail && (
              <div className='help-block'>L'adresse mail est requise</div>
            )}
          </div>
          <div
            className={
              'form-group' + (submitted && !password ? ' has-error' : '')
            }
          >
            <input
              type='password'
              className='form-control inputDefaultTheme'
              name='password'
              value={password}
              onChange={this.handleChange}
              placeholder='Mot de passe'
            />
            {submitted && !password && (
              <div className='help-block'>Le mot de passe est requis</div>
            )}
          </div>
          <p className='text-primary text-xs mb-2'>
            Conformément à la législation en vigueur en termes de Protection des
            données personnelles, remplir ce formulaire entraîne le traitement
            de vos données personnelles par la Ville de Ouistreham Riva-Bella,
            fondé sur votre consentement, et uniquement dans le but de créer un
            compte. Pour l’exercice de vos droits, retrouvez toutes les
            informations
            <a
              class='text-primary text-xs'
              href='http://ouistreham-rivabella.fr/mentions-legales/protection-de-la-vie-privee-rgpd/'
              target='_blank'
              rel="noopener noreferrer"
            >
              &nbsp; ICI
            </a>
          </p>
          <div className='form-group'>
            <SubmitButton loading={loading}>Créer mon compte</SubmitButton>
            {loading && (
              <img
                alt=''
                className='mx-auto'
                src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
              />
            )}
          </div>
          {error && <div className='error'>{error}</div>}
        </form>
        <div className='w-full text-center'>
          <Link className='text-primary underline text-base' to='/connexion'>
            Se connecter
          </Link>
        </div>
      </div>
    )
  }
}

export { CreateAccount }
