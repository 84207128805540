import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const PublicRoute = ({ component: Component, isAllowed, ...rest }) => (
  <Route
    {...rest} render={props => (
      isAllowed
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/parametres', state: { from: props.location } }} />
    )}
  />
)
