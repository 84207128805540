export const traduction = {
  trad
}

function trad (text) {
  for (var key in fr_FR) {
    var re = RegExp('^' + key)
    if (re.test(text)) { return fr_FR[key] }
  }
  return text
}

const fr_FR = {
  'Sorry, unrecognized username or password.': 'Login ou mot de passe non reconnu. (erreur: 1)',
  'Unprocessable Entity: validation failed.': 'Erreur : cette adresse email est déjà prise',
  'This route can only be accessed by anonymous users.': 'Une erreur s\'est produite. (erreur: 3)',
  'X-CSRF-Token request header is invalid': 'Une erreur s\'est produite. (erreur: 4)',
  'name, new_pass, and temp_pass fields are required': 'Tous les champ sont obligatoires. (erreur: 5)',
  'The recovery password is not valid.': 'Le code de réinitialisation c\'est pas valide. (erreur: 6)',
  'Your New Password has been saved please log in.': 'Votre nouveau mot de passe à été sauvegardé. (erreur: 7)',
  'The "user" parameter was not converted': 'Une erreur s\'est produite. (erreur: 8)',
  "Sorry system can't send email at the moment": 'Réinitialisation de mot de passe indisponible. (erreur: 9)',
}
