import React from 'react'

export const SubmitButton = ({ loading, children }) => (
  <button
    className='bg-secondary
    hover:bg-secondary-700
    text-white
    w-full
    font-bold
    py-4
    px-4
    focus:outline-none
    focus:shadow-outline
    rounded
    uppercase
    mb-5'
    disabled={loading}
  >
    {children}
  </button>
)
