import React from 'react'
import { isEmpty } from 'lodash'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'

export const TermAccordions = ({ termes }) => (
  <div>
    <Accordion>
      {!isEmpty(termes) && termes.map((item, i) =>
        <AccordionItem key={i}>
          <AccordionItemHeading>
            <AccordionItemButton className='flex justify-between accordion__button'>
              <div className='termName'>
                {item.term.name}
              </div>
              <div className='flex items-center pr-5'>
                <div className='infosCount mr-5'>
                  {item.infos.length}
                </div>
                <i className='icofont-simple-down icofont icofont-lg' />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {!isEmpty(item.infos) && item.infos.map((info, i) =>
              <div key={i} className='infoContent'>
                <div className='borderLeft'>
                  <p className='contenu'>
                    {info.contenu}
                  </p>
                  {info.adresse &&
                    <p className='adresse'>
                      <span className='icofont-location-pin' />
                      {info.adresse}
                    </p>}

                </div>
              </div>
            )}
          </AccordionItemPanel>
        </AccordionItem>
      )}
    </Accordion>
  </div>
)
