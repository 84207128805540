import React, { Component } from 'react'
import { SimpleContentService } from '../Services/SimpleContentService'
import Select from 'react-select'

class SelectTheme extends Component {
  constructor (props) {
    super(props)
    this.state = {
      themes: [],
      defaultThemes: [],
      isLoaded: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    SimpleContentService.taxoRequete('thematiques').then(
      text => {
        const data = JSON.parse(text)
        this.setState({
          themes: data.message ? data.message : ''
        })
        if (this.props.defaultThemeState) {
          this.setDefaultThemes(this.props.defaultThemeState.themes)
        }
        this.setState({
          isLoaded: true
        })
      },
      error => this.setState({ error, loading: false })
    )
  }

  setDefaultThemes (defaults) {
    const defaultThemes = []
    const themes = this.state.themes
    defaults.forEach(function (itemDefault) {
      const selected = themes.find(function (itemThemes) {
        return itemThemes.value === itemDefault
      })
      defaultThemes.push(selected)
    })
    this.setState({
      defaultThemes: defaultThemes
    })
  }

  handleChange (selectedOption) {
    this.props.themeChange(selectedOption)
  }

  render () {
    const { isLoaded, themes, defaultThemes } = this.state
    return (
      <div>
        {isLoaded === true ? (
          <Select
            isMulti
            name='colors'
            options={themes}
            className='basic-multi-select'
            classNamePrefix='select'
            placeholder='Thèmes'
            isSearchable={false}
            hideSelectedOptions={false}
            isClearable
            onChange={this.handleChange}
            defaultValue={defaultThemes}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

export { SelectTheme }
