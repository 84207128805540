import React from 'react'
import { Link } from 'react-router-dom'

const links = [
  {
    key: 1,
    icon: 'icofont-home',
    link: '/',
    title: 'Accueil'
  },
  {
    key: 2,
    icon: 'icofont-exclamation-circle',
    link: '/actus',
    title: 'Actualités'
  },
  {
    key: 3,
    icon: 'icofont-list-alt',
    link: '/requete/envoi',
    title: 'Requête'
  },
  {
    key: 4,
    icon: 'icofont-info-circle',
    link: '/infos-pratiques',
    title: 'Infos pratiques'
  },
  {
    key: 5,
    icon: 'icofont-navigation-menu',
    link: '/menu',
    title: 'Menu'
  }
]

class Footer extends React.Component {
  constructor (props) {
    super(props)
    this.isCurrent = this.isCurrent.bind(this)
  }

  isCurrent (e) {
    var currentLocation = this.props.location.pathname
    return e === currentLocation
  }

  render () {
    return (
      <footer className='mainFooter'>
        <div className='flex'>
          {links.map((item) =>
            <div className='flex-1 linkItem' key={item.key}>
              <Link to={item.link} className={this.isCurrent(item.link) ? 'current' : ''}>
                <div className='text-center'>
                  <span className={item.icon + ' block icofont-3x'} />
                  <span>{item.title}</span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </footer>
    )
  }
}

export { Footer }
