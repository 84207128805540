import Conf from '../Configuration/Configuration'

export const userService = {
  login,
  getResetCode,
  resetPassword,
  createAccount,
  modifyAccount,
  getAccountValue
}

function login (username, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache'
    },
    credentials: 'include',
    body: JSON.stringify({ name: username, pass: password })
  }

  return fetch(`${Conf.BaseURL}user/login?_format=json`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

function getAccountValue (id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }

  return fetch(`${Conf.BaseURL}user/${id}?_format=json`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

function createAccount (name, firstName, mail, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name: {
        value: mail
      },
      mail: {
        value: mail
      },
      pass: {
        value: password
      },
      field_nom: {
        value: name
      },
      field_prenom: {
        value: firstName
      }
    })
  }

  return fetch(`${Conf.BaseURL}user/register?_format=json`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return true
    })
}

function modifyAccount (name, firstName, mail, id, token) {
  const data = {
    mail: {
      value: mail
    },
    field_nom: {
      value: name
    },
    field_prenom: {
      value: firstName
    }
  }
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': token
    },
    credentials: 'include',
    body: JSON.stringify(data)
  }

  return fetch(`${Conf.BaseURL}/user/${id}?_format=json`, requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user) {
        return user
      }
    })
}

function getResetCode (mail) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      mail: mail
    })
  }

  return fetch(`${Conf.BaseURL}user/lost-password?_format=json`, requestOptions)
    .then(handleResponse)
    .then(message => {
      return JSON.parse(message)
    })
}

function resetPassword (mail, code, newPass) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name: mail,
      'temp_pass': code,
      'new_pass': newPass
    })
  }

  return fetch(`${Conf.BaseURL}user/lost-password-reset?_format=json`, requestOptions)
    .then(handleResponse)
    .then(message => {
      return JSON.parse(message)
    })
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text
    if (!response.ok) {
      const dataObj = JSON.parse(data)
      const error = (data && dataObj.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
