import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Header } from '../Component/Header'

class AccountParamPage extends Component {
  render () {
    const { logOut } = this.props
    return (
      <div className='InfosWrapper'>
        <Header title='Paramètres' />
        <div className='mainContent resetPaddingX'>
          <ul className='defaultListe'>
            <li className='flex items-center justify-between" key={i}'>
              <Link
                className='w-full listeItem'
                to={{
                  pathname: '/mon-compte',
                  subpage: 'Paramètres'
                }}
              >
                Mon compte
              </Link>
              <i className='icofont-simple-right icofont-md pr-8' />
            </li>
            <li>
              <button className='logoutButton listeItem' onClick={logOut}>
                Se déconnecter
              </button>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export { AccountParamPage }
