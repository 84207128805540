import React from 'react'
import PropTypes from 'prop-types'

export const Modal = ({ children, customClass, closeModal }) => (
  <div className='modalWrapper'>
    <div className={`modalContent ${customClass}`}>
      <div className='relative'>
        <div className='close-image' onClick={closeModal}>
          <span className='icofont-close' />
        </div>
      </div>
      {children}
    </div>
  </div>
)

Modal.propTypes = {
  children: PropTypes.element,
  customClass: PropTypes.string,
  closeCallback: PropTypes.func,
}

Modal.defaultProps = {
  children: '<div>Empty Modal</div>',
  customClass: '',
  closeCallback: () => (false)
}
