import React, { Component } from 'react'
import { isEmpty } from 'lodash'

import { Header } from '../Component/Header'
import { TermAccordions } from '../Component/TermAccordions'
import { InfosService } from '../Services/InfosService'

class InfosDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      termes: []
    }
  }

  componentDidMount () {
    const { term } = this.props.match.params
    InfosService.getSecondDepthThemes(term)
      .then(
        text => {
          const data = JSON.parse(text)
          this.setState({
            termes: data.message ? data.message : ''
          })
        },
        error => this.setState({ error, loading: false })
      )
  }

  render () {
    const {
      termes
    } = this.state
    return (
      <div className='InfosWrapper'>
        <Header title='Actualités' history={this.props.history} subpage={this.props.location.subpage} />
        <div className='mainContent noPadding'>
          {!isEmpty(termes) && <TermAccordions termes={termes} />}
        </div>
      </div>
    )
  }
}

export { InfosDetail }
