import React, { Component } from 'react'
import { isEmpty } from 'lodash'
import { Header } from '../Component/Header'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import { ContactService } from '../Services/ContactService'

class ContactPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contact_infos: {}
    }
  }

  componentDidMount () {
    ContactService.getContactInfos()
      .then(
        text => {
          const data = JSON.parse(text)
          this.setState({
            contact_infos: data || {}
          })
        },
        error => this.setState({ error, loading: false })
      )
  }

  render () {
    const { contact_infos } = this.state
    return (
      <div>
        <Header title='Nous contacter' />
        <Accordion preExpanded={['preExpanded-0']}>
          {!isEmpty(contact_infos) && contact_infos.map((info, i) =>
            <AccordionItem key={i} uuid={`preExpanded-${i}`}>
              <AccordionItemHeading>
                <AccordionItemButton className='flex justify-between accordion__button'>
                  <div className='termName'>
                    {info.name}
                  </div>
                  <div className='flex items-center pr-5'>
                    <i className='icofont-simple-down icofont icofont-lg' />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul className='infoContent'>
                  {info.address &&
                    <li className='adresse infoContentItem'>
                      <span className='icofont icofont-location-pin' />
                      {info.address}
                    </li>}
                  {info.phone &&
                    <li className='infoContentItem'>
                      <span className='icofont icofont-phone' />
                      <a href={`tel:${info.phone}`}>{info.phone}</a>
                    </li>}
                  {info.email &&
                    <li className='infoContentItem'>
                      <span className='icofont icofont-envelope' />
                      <a href={`mailto:${info.email}`}>{info.email}</a>
                    </li>}
                  {info.website &&
                    <li className='infoContentItem'>
                      <span className='icofont icofont-link' />
                      <a target='_blank' rel='noopener noreferrer' href={info.website} title={`Lien vers le site ${info.name}`}>
                        {info.website}
                      </a>
                    </li>}
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
          )}
        </Accordion>
      </div>
    )
  }
}

export { ContactPage }
