import React from 'react'

export const DeletePhoto = ({ image, deletePhoto }) => (
  <>
    <img src={image.url} alt='' />
    <div className='deleteImg' onClick={deletePhoto}>
      <button className='btn-warning mb-5 mt-8' data-url={image.url} data-name={image.name}>
        Supprimer la photo
      </button>
    </div>
  </>
)
