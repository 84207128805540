import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { userService } from '../Services/UserService'
import { traduction } from '../Services/Traduction'
import { SubmitButton } from '../Component/SubmitButton'
import { Header } from '../Component/Header'

class AccountPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      username: '',
      mail: '',
      firstName: '',
      message: '',
      notification_mail: '',
      submitted: false,
      loading: false,
      error: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleCheckboxChange = name => event => {
    this.setState({ ...this.state, [name]: event.target.checked });
  }

  componentDidMount () {
    const { user } = this.props
    userService.getAccountValue(user.current_user.uid, user.csrf_token)
      .then(
        user => {
          const userData = JSON.parse(user)
          this.setState({
            username: userData.field_nom[0] ? userData.field_nom[0].value : '',
            firstName: userData.field_prenom[0] ? userData.field_prenom[0].value : '',
            mail: userData.mail[0].value
          })
        }
      )
  }

  handleChange (e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit (e) {
    e.preventDefault()
    const { user } = this.props
    const { username, firstName, mail } = this.state

    this.setState({
      submitted: true
    })

    if (!(username && firstName && mail)) {
      return
    }

    this.setState({
      loading: true
    })

    userService.modifyAccount(username, firstName, mail, user.current_user.uid, user.csrf_token)
      .then(
        user => {
          const userData = JSON.parse(user)
          this.setState({
            username: userData.field_nom[0] ? userData.field_nom[0].value : '',
            firstName: userData.field_prenom[0] ? userData.field_prenom[0].value : '',
            mail: userData.mail[0].value,
            message: 'Modification effectuée',
            loading: false
          })
        }, error => {
          let msgError = 'Une erreur s\'est produite. (erreur:0)'
          if (typeof error === 'string') {
            msgError = traduction.trad(error)
          }
          this.setState({ error: msgError, loading: false })
        }
      )
  }

  render () {
    const { username, mail, firstName, submitted, loading, message, error } = this.state
    const { history, location } = this.props
    return (
      <>
        <Header title='Modifier mes informations' history={history} subpage={location.subpage} />
        <div className='px-8 py-4'>
          <form name='form w-100 mb-6' onSubmit={this.handleSubmit}>
            <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
              <input type='text' className='form-control inputDefaultTheme' name='username' value={username} onChange={this.handleChange} placeholder='Nom' />
              {submitted && !username &&
                <div className='help-block'>Le nom est requis</div>}
            </div>
            <div className={'form-group' + (submitted && !firstName ? ' has-error' : '')}>
              <input type='text' className='form-control inputDefaultTheme' name='firstName' value={firstName} onChange={this.handleChange} placeholder='Prenom' />
              {submitted && !firstName &&
                <div className='help-block'>Le prénom est requis</div>}
            </div>
            <div className={'form-group' + (submitted && !mail ? ' has-error' : '')}>
              <input type='email' className='form-control inputDefaultTheme' name='mail' value={mail} onChange={this.handleChange} placeholder='Adresse mail' />
              {submitted && !mail &&
                <div className='help-block'>L'adresse mail est requise</div>}
            </div>
            <div className='py-8 my-8'>
              <div className='form-group'>
                <SubmitButton loading={loading}>SAUVEGARDER MES CHANGEMENTS</SubmitButton>
                {loading &&
                  <img alt='' className='mx-auto' src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==' />}
              </div>
            </div>
            {message &&
              <div className='success'>{message}</div>}

            {error &&
              <div className='error'>{traduction.trad(error)}</div>}

            <div className='text-center w-full'>
              <Link className='text-primary underline text-base' to={{
                pathname: '/mot-de-passe',
                state: mail
            }}>Réinitialiser mon mot de passe</Link>
            </div>
          </form>
        </div>
      </>
    )
  }
}

export { AccountPage }
