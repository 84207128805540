import Conf from '../Configuration/Configuration'

export const PageService = {
  getPage
}

function getPage (title) {
  return fetch(`${Conf.BaseURL}api/page?_format=json&title=${title}`)
    .then(handleResponse)
    .then(text => {
      return text
    })
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text
    if (!response.ok) {
      const dataObj = JSON.parse(data)
      const error = (data && dataObj.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}
