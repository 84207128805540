import React from 'react'
import { Link } from 'react-router-dom'

export const MenuListItem = ({ item, location }) => (
  <li
    className={item.isLocked
      ? 'menuListItem isLocked py-3 flex items-center'
      : 'menuListItem py-3 flex items-center'}
  >
    {item.icon && <span className={item.icon + ' icofont-1x text-primary mr-3'} />}
    <Link location={location} className='flex-1 text-primary font-medium' to={item.link}>
      {item.title}
    </Link>
    {item.isLocked && <span className='icofont-lock pr-6' />}
  </li>
)
