import React from 'react'

export const CustomCheckbox = ({name, children, disabled, onChange, display }) => (
  <div className='pretty p-icon p-curve p-pulse mb-4 w-full'>
        <input name={name} type='checkbox' disabled={disabled} onChange={onChange} />
        <div className='state p-primary-o'>
          <i className='icon icofont icofont-check' />
          {children}
        </div>

  </div>
)
