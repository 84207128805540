import Conf from '../Configuration/Configuration'

export const RequeteListService = {
  getRequestListOfCurrentUser,
  getRequest
}

function getRequestListOfCurrentUser (uid, token) {
  return fetch(`${Conf.BaseURL}api/user/${uid}/list/request`, {
    headers: {
      'X-CSRF-Token': token,
    },
    credentials: 'include'
  })
    .then(handleResponse)
    .then(text => {
      return text
    })
}

function getRequest (nid, uid, token) {
  return fetch(`${Conf.BaseURL}api/user/${uid}/request/${nid}`, {
    headers: {
      'X-CSRF-Token': token
    },
    credentials: 'include'
  })
    .then(handleResponse)
    .then(text => {
      return text
    })
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text
    if (!response.ok) {
      const dataObj = JSON.parse(data)
      const error = (data && dataObj.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
