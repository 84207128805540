import Conf from '../Configuration/Configuration'

export const InfosService = {
  getFirstDepthThemes,
  getSecondDepthThemes
}

function getFirstDepthThemes () {
  return fetch(Conf.BaseURL + 'api/informations?_format=json')
    .then(handleResponse)
    .then(text => {
      return text
    })
}

function getSecondDepthThemes (term) {
  return fetch(Conf.BaseURL + 'api/informations?_format=json&term=' + term)
    .then(handleResponse)
    .then(text => {
      return text
    })
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text
    if (!response.ok) {
      if (response.status === 401) {
        document.location.reload(true)
      }
      const dataObj = JSON.parse(data)
      const error = (data && dataObj.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
