import React, { Component } from 'react'
import { isEmpty } from 'lodash'

import { Header } from '../Component/Header'
import { RequeteListService } from '../Services/RequeteListService'

class RequestPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      content: {}
    }
  }

  componentDidMount () {
    RequeteListService.getRequest(
      this.props.location.state || window.location.pathname.split('/').pop(),
      this.props.user.current_user.uid,
      this.props.user.csrf_token
    )
      .then(
        text => {
          const data = JSON.parse(text)
          this.setState({
            content: data || {}
          })
        },
        error => this.setState({ error, loading: false })
      )
  }

  render () {
    const { content } = this.state
    const {
      location, history
    } = this.props
    return (
      <>
        <Header title={content.titre} history={history} subpage={location.subpage} />
        <div className='px-6 py-3 bg-lightblue'>
            <p className='font-bold text-primary mb-2'>État de ma requête : </p>
            <p className='text-primary'>
              <i className='icofont-hour-glass text-primary pr-1'/>
              {content.etat}
            </p>
        </div>
        <div className='mainContent'>
          <div>
            {content.description &&
              <div className='rich-text mb-5' dangerouslySetInnerHTML={{ __html: content.description }} />}
            {content.adresse &&
              <p className='my-5 text-primary'>
                <span className='icofont icofont-location-pin '/>
                {content.adresse}
              </p>}
          </div>
          {!isEmpty(content.photos) &&
            <div>
              <h2>Photos de l’incident</h2>
              <div className='flex -mx-2 mb-5'>
                {content.photos && content.photos.map((photo, i) => (
                  <div className='w-1/2 px-2 incident-photo' key={i}>
                    <img src={photo} className='rounded' alt='' />
                  </div>
                ))}
              </div>
            </div>}
        </div>
      </>
    )
  }
}

export { RequestPage }
