import React, { Component } from 'react'

class Header extends Component {
  constructor (props) {
    super(props)
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.history.goBack()
  }

  render () {
    return (
      <header className='mainHeader'>

        <h1 className={this.props.history ? 'goBack' : ''}>
          {this.props.history && <span className='icofont-simple-left icofont-md' onClick={this.goBack} />}
          {this.props.title}
          {this.props.subpage && <span> -<span className='subpage'> {this.props.subpage} </span></span>}
        </h1>
      </header>
    )
  }
}

export { Header }
