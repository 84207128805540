import React from 'react'

import { Header } from '../Component/Header'
import { MenuListItems } from '../Component/MenuListItems'

class MenuMainPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      menu: [
        {
          key: 0,
          icon: 'icofont-home',
          link: '/',
          title: 'Accueil',
          isLocked: false
        },
        {
          key: 1,
          icon: 'icofont-list-alt',
          link: '/requete/envoi',
          title: 'Requête',
          isLocked: false
        },
        {
          key: 2,
          icon: 'icofont-list',
          link: '/mon-compte/mes-requetes',
          title: 'Mes requêtes',
          isLocked: !this.props.isAllowed
        },
        {
          key: 3,
          icon: 'icofont-exclamation-circle',
          link: '/actus',
          title: 'Actualités',
          isLocked: false
        },
        {
          key: 4,
          icon: 'icofont-info-circle',
          link: '/infos-pratiques',
          title: 'Infos pratiques',
          isLocked: false
        },
        {
          key: 5,
          icon: 'icofont-institution',
          link: '/mot-du-maire',
          title: 'Mot du maire',
          isLocked: false
        }
      ],
      submenu: [
        /*{
          key: 0,
          icon: '',
          link: '/aide',
          title: 'Aide',
          isLocked: false
        },*/
        {
          key: 1,
          icon: '',
          link: '/contact',
          title: 'Nous contacter',
          isLocked: false
        },
        {
          key: 2,
          icon: '',
          link: '/parametres',
          title: 'Paramètres',
          isLocked: !this.props.isAllowed
        }
        /*{
          key: 3,
          icon: '',
          link: '/a-propos',
          title: 'À propos',
          isLocked: false
        }*/
      ]
    }
  }

  render () {
    return (
      <div className='contentWrapper flex flex-col'>
        <Header title='Menu' />
        <nav className='menu'>
          <MenuListItems
            location={this.props.location}
            menu={this.state.menu}
          />
        </nav>
        <nav className='contentFooter submenu flex-1'>
          <MenuListItems
            location={this.props.location}
            menu={this.state.submenu}
          />
        </nav>
      </div>
    )
  }
}

export { MenuMainPage }
