import React from 'react'

import { MenuListItem } from '../Component/MenuListItem'

export const MenuListItems = ({ location, menu }) => (
  <ul className='menuList pl-6'>
    {menu.map(item => (
      <MenuListItem location={location} key={item.key} item={item} />
    ))}
  </ul>
)
