import Conf from '../Configuration/Configuration'

export const RequeteService = {
  sendRequete
}

function sendRequete (token, state) {
  const formData = new FormData()

  formData.append('description', state.description)
  formData.append('localisation', state.localisation)
  formData.append('coords', JSON.stringify(state.coords))
  formData.append('prenom', state.prenom)
  formData.append('nom', state.nom)
  formData.append('notification_resolution', state.notification_resolution)
  formData.append('notification_requete', state.notification_requete)
  formData.append('themes', state.themes)

  for (var i = 0; i < state.files.length; i++) {
    formData.append('files[]', state.files[i])
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type':'multipart/form-data',
      'X-CSRF-Token': token
    },
    credentials: 'include',
    body: formData
  }

  delete requestOptions.headers['Content-Type']

  return fetch(Conf.BaseURL + 'api/upload/file', requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text
    if (!response.ok) {
      const dataObj = JSON.parse(data)
      const error = (data && dataObj.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
