import React from 'react'
import { SimpleContentService } from '../Services/SimpleContentService'
import { BasicPage } from '../Page/BasicPage'


class RequestSendValidPage extends React.Component {
  link = {
    label: 'Retour à l\'accueil',
    link: '/'
  }

  constructor (props) {
    super(props)

    this.state = {
      titre: 'Requête envoyé',
      texte: ''
    }
  }

  componentDidMount () {
    SimpleContentService.textRequete('requete_valid')
      .then(
        text => {
          const data = JSON.parse(text)
          this.setState({
            texte: data.message ? data.message : ''
          })
        },
        error => this.setState({ error, loading: false })
      )
  }

  render () {
    return (
      <BasicPage content={this.state} link={this.link} />
    )
  }
}

export { RequestSendValidPage }
