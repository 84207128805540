import React from 'react'
import { Link } from 'react-router-dom'
import { userService } from '../Services/UserService'
import { traduction } from '../Services/Traduction'

import { SubmitButton } from '../Component/SubmitButton'

import logo from '../assets/images/logo.jpg'

class ForgottenPassword extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      mail: this.props.location.state || '',
      message: '',
      submitted: false,
      loading: false,
      firstStep: false,
      submitedNext: false,
      error: '',
      tempCode: '',
      newPassword: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit (e) {
    e.preventDefault()
    const { mail, tempCode, newPassword, firstStep } = this.state

    if (!firstStep) {
      this.setState({ submitted: true })
      if (!(mail)) {
        return
      }
      this.setState({ loading: true })
      userService.getResetCode(mail)
        .then(
          message => {
            this.setState({
              message: message.message,
              loading: false,
              firstStep: true
            })
          },
          error => {
            let msgError = 'Une erreur s\'est produite.'
            if (typeof error === 'string') {
              msgError = traduction.trad(error)
            }
            this.setState({ error: msgError, loading: false })
          }
        )
    } else {
      this.setState({ submitedNext: true })
      if (!(mail) && !(newPassword) && !(tempCode)) {
        return
      }
      this.setState({ loading: true })
      userService.resetPassword(mail, tempCode, newPassword)
        .then(
          message => {
            this.setState({
              message: message.message,
              loading: false,
            })
          },
          error => {
            let msgError = 'Une erreur s\'est produite.'
            if (typeof error === 'string') {
              msgError = traduction.trad(error)
            }
            this.setState({ error: msgError, loading: false })
          }
        )
    }
  }

  render () {
    const { mail, submitted, loading, error, message, tempCode, newPassword, firstStep, submitedNext } = this.state
    const { isAuthenticated } = this.props
    return (
      <div className='px-8 py-4'>
        <div className='mb-6 text-center'>
          <img className='mx-auto mb-6' src={logo} alt='logo Ouistreham' />
          <h1 className='no-style font-bold text-center text-primary text-base mb-3'>Mot de passe oublié ?</h1>
          <p className='font-bold text-center text-primary text-base'>Renseignez votre adresse mail nous vous enverrons un code de réinitialisation temporaire</p>
        </div>
        <form autocomplete="off" name='form' onSubmit={this.handleSubmit}>
          <div className={'form-group' + (submitted && !mail ? ' has-error' : '')}>
            <input type='email' className='form-control inputDefaultTheme' name='mail' value={mail} onChange={this.handleChange} placeholder='Adresse mail' />
            {(submitted || submitedNext) && !mail &&
              <div className='help-block'>L'adresse mail est requise</div>}
          </div>
          <p className='text-center font-bold text-primary mb-3'>
            {message}
          </p>
          {firstStep &&
            <>
              <div className={'form-group' + (submitedNext && !tempCode ? ' has-error' : '')}>
              <input className='form-control inputDefaultTheme' type="text" autocomplete="off" name="tempCode"  placeholder='Code de réinitialisation temporaire'  onChange={this.handleChange}/>
                {submitedNext && !tempCode &&
                  <div className='help-block'>Un code de réinitialisation est requis</div>}
              </div>
              <div className={'form-group' + (submitedNext && !newPassword ? ' has-error' : '')}>
              <input
                type='password'
                autocomplete="new-password"
                className='form-control inputDefaultTheme'
                name='newPassword'
                onChange={this.handleChange}
                placeholder='Nouveau mot de passe' />
                {submitedNext && !newPassword &&
                  <div className='help-block'>Veuillez saisir un nouveau mot de passe</div>}
              </div>
            </>}
          <div className='form-group'>
            <SubmitButton loading={loading}>{message ? 'Confirmer le changement de mot de passe' : 'Envoyer un code de réinitialisation'}</SubmitButton>
            {loading &&
              <img className='mx-auto' alt='' src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==' />}
          </div>
          {error &&
            <div className='error mb-5'>{traduction.trad(error)}</div>}
          {!isAuthenticated &&
            <div className='w-full text-center'>
              <Link className='text-primary underline text-base' to='/connexion'>Se connecter</Link>
            </div>}
        </form>
      </div>
    )
  }
}

export { ForgottenPassword }
