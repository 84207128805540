import React from 'react'
import { Header } from '../Component/Header'
import { Link } from 'react-router-dom'

export const BasicPage = ({ content, location, history, link, ...props }) => {
  return (
    <>
      <Header title={content.titre} history={history} subpage={location} />
      <div className='basicPage content px-6 pt-6 pb-12'>
        {content.image && (
          <figure className='is-caption rounded mb-5'>
            <img className='rounded' src={content.image} alt={content.titre} />
            {content.legende && (
              <figcaption
                className='rounded img-caption'
                dangerouslySetInnerHTML={{ __html: content.legende }}
              />
            )}
          </figure>
        )}
        <div
          className='rich-text'
          dangerouslySetInnerHTML={{ __html: content.texte }}
        />
        {link && (
          <Link className='btn-default mt-5' to={link.link} title={link.label}>
            {link.label}
          </Link>
        )}
      </div>
    </>
  )
}
