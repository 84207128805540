import React, { Component } from 'react'

import { BasicPage } from './BasicPage'
import { PageService } from '../Services/PageService'

class Page extends Component {
  constructor (props) {
    super(props)
    this.state = {
      content: {}
    }
  }

  componentDidMount () {
    PageService.getPage(this.props.title)
      .then(
        text => {
          const data = JSON.parse(text)
          this.setState({
            content: data || ''
          })
        },
        error => this.setState({ error, loading: false })
      )
  }

  render () {
    return (
      <BasicPage content={this.state.content} />
    )
  }
}

export { Page }
