import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { Header } from '../Component/Header'
import { RequeteListService } from '../Services/RequeteListService'

class RequestListPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      requests: []
    }
  }

  componentDidMount () {
    RequeteListService.getRequestListOfCurrentUser(
      this.props.user.current_user.uid,
      this.props.user.csrf_token
    )
      .then(
        text => {
          const data = JSON.parse(text)
          this.setState({
            requests: data || {}
          })
        },
        error => this.setState({ error, loading: false })
      )
  }

  render () {
    const { requests } = this.state
    return (
      <div>
        <Header title='Mes requêtes' />
        {!isEmpty(requests)
          ? <div className='mainContent paddingOnlyLeft'>
            <ul className='InfosListe'>
              {requests.map((item, i) =>
                <li className='flex items-center justify-between' key={i}>
                  <Link
                    className='w-full'
                    to={{
                      pathname: `/mon-compte/requete/${item.id}`,
                      state: item.id,
                      subpage: 'Mes requêtes'
                    }}
                  >
                    {item.titre}
                  </Link>
                  <i className='icofont-simple-right icofont-md pr-8' />
                </li>
              )}
            </ul>
          </div> : <div className='content px-6 py-6'>
            <p className='py-6 font-bold'>Aucune soumission n'a été envoyé.</p>
            <Link
              className='btn-default mb-5'
              to={{
                pathname: '/requete/envoi'
              }}
            >Soumettre une requête
            </Link>
          </div>}
      </div>
    )
  }
}

export { RequestListPage }
