import Conf from '../Configuration/Configuration'

export const SimpleContentService = {
  textRequete,
  taxoRequete
}

function textRequete (param) {
  return fetch(Conf.BaseURL + 'api/simpleContent?_format=json&param=' + param)
    .then(handleResponse)
    .then(text => {
      return text
    })
}

function taxoRequete (taxo) {
  return fetch(Conf.BaseURL + 'api/simpleContent?_format=json&taxo=' + taxo)
    .then(handleResponse)
    .then(text => {
      return text
    })
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text
    if (!response.ok) {
      const dataObj = JSON.parse(data)
      const error = (data && dataObj.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
